define('dolar-portfolio/components/photo-gallery', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['gallery-outer'],
    gallery_style: Ember.computed('body_width', 'body_height', 'photos', function () {
      if (this.get('body_width') >= 700) {
        var multiplier = 1;

        if (this.get('body_height') <= 900) {
          multiplier = 0.6;
        } else if (this.get('body_height') <= 1100) {
          multiplier = 0.8;
        }

        return {
          height: this.get('body_width') / multiplier + 'px'
        };
      } else {
        return {
          height: this.get('body_height') - 125 + 'px'
        };
      }
    }),
    thumbnails: Ember.computed('body_width', 'path', 'photos', function () {
      var photos = [];

      var offsets = [0, 0];

      if (this.get('photos')) {
        for (var i = 0; i < this.get('photos').length; i++) {
          var photo = this.get('photos')[i];
          var margin_top = 0;
          var margin_left = 0;

          if (this.get('body_width') >= 700) {
            margin_top = photo.th.h == 300 && i % 2 == 0 ? 100 : 0;

            if (i > 1) {
              var offset = offsets[i % 2];

              margin_left = offset + (this.get('photos')[i - 2].th.w == 300 ? -100 : 0);

              offsets[i % 2] = margin_left;
            }

            if (i % 2 == 1) {
              margin_top += 25;
            }

            if (i > 1) {
              margin_left += Math.floor(i / 2) * 25;
            }
          } else {}

          photos.push({
            src: '/assets/images/' + this.get('path') + '/thumbnails/' + photo.name,
            name: photo.name.split('.')[0],
            style: 'margin-top: ' + margin_top + 'px; margin-left: ' + margin_left + 'px;'
          });
        }
      }

      return photos;
    }),
    handleResize: function handleResize() {
      this.set('body_width', Ember.$(window).outerWidth());
      this.set('body_height', Ember.$(window).outerHeight());

      console.log(this.get('gallery_style'));

      Ember.$('#' + this.elementId + ' > .gallery').css(this.get('gallery_style'));
    },
    didInsertElement: function didInsertElement() {
      this.handleResize();

      Ember.$(window).on('resize', this.handleResize.bind(this));

      var self = this;
      Ember.$.getJSON('https://www.backend.pixone.pl/get_photos.php?path=' + this.get('path')).then(function (photos) {
        self.set('photos', photos);
      });
    },

    image: Ember.computed('opened', 'path', function () {
      return {
        src: '/assets/images/' + this.get('path') + '/images/' + this.get('opened').split('.')[0]
      };
    }),
    actions: {
      open: function open(name) {
        this.set('opened', name);
      },
      close: function close() {
        this.set('opened', null);
      }
    }
  });
});