define('dolar-portfolio/routes/gallery/photo', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      console.log();

      return {
        path: this.paramsFor('gallery').path,
        id: params.id,
        src: '/assets/images/' + this.paramsFor('gallery').path + '/images/' + params.id
      };
    }
  });
});