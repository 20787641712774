define('dolar-portfolio/router', ['exports', 'dolar-portfolio/config/environment', 'dolar-portfolio/mixins/google-pageview'], function (exports, _environment, _googlePageview) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend(_googlePageview.default, {
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('contact');
    // this.route('portfolio');
    // this.route('foto');
    this.route('gallery', {
      path: '/:path/'
    }, function () {
      this.route('photo', {
        path: '/:id/'
      });
    });
  });

  exports.default = Router;
});