define('dolar-portfolio/routes/gallery', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return {
        path: params.path,
        name: params.path === 'portfolio' ? "Portfolio" : "Fotografia",
        anchor: params.path === 'portfolio' ? 'branding' : 'fotografia'
      };
    }
  });
});