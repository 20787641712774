define('dolar-portfolio/integrations/mixpanel', ['exports', 'ember-cli-analytics/integrations/mixpanel'], function (exports, _mixpanel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _mixpanel.default;
    }
  });
});