define('dolar-portfolio/controllers/contact', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      submit: function submit() {
        var self = this;

        Ember.$.post('http://backend.pixone.pl/send_message.php', this.getProperties('name', 'email', 'telephone', 'message')).then(function () {
          self.set('success', true);
        });
      }
    }
  });
});